<template>
  <div id="hero">
    <div class="left">
      <div class="usp">
        <img src="logo_nightsalon.svg" alt="夜の社交場情報">
        <div>
          <img src="logo_nightsalon_alt.svg" alt="夜の社交場情報">
          <img src="logo_nightsalon_alt.svg" alt="夜の社交場情報">
        </div>
      </div>
    </div>
    <div class="right">
      <picture :class="{ loading: !imgsrc }">
        <div class="loader" v-if="!imgsrc"></div>
        <img
          v-else
          :src="imgsrc"
          sizes="(min-width: 600px) 50vw, 100vw"
          :srcset="srcset"
          alt="夜の社交場情報"
          @click="showArtist(nameAlt)"
        >
        <span>{{ title }}</span>
        <div class="prev" @click.stop="loadPic('prev')"><span></span></div>
        <div class="next" @click.stop="loadPic('next')"><span></span></div>
      </picture>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import utility from '@/mixins/utility.js'
export default {
  mixins: [utility],
  data () {
    return {
      id: '',
      imgsrc: '',
      nameAlt: '',
      title: '',
      srcset: ''
    }
  },
  mounted () {
    this.getHeroImage()
  },
  methods: {
    async getHeroImage () {
      await axios
        .get('/wp-json/mmt/v1/hero')
        .then((response) => {
          console.log(response.data)
          this.id = response.data.id
          this.imgsrc = response.data.url.large
          this.srcset = `${response.data.url.large} 1600w,
          ${response.data.url.medium} 1200w,
          ${response.data.url.small} 800w`
          this.nameAlt = response.data.nameAlt
          this.title = response.data.title
          this.id = response.data.id
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async loadPic (direction) {
      this.title = ''
      this.imgsrc = ''
      await axios
        .post('/wp-json/mmt/v1/hero/', {
          current: this.id,
          mode: direction
        })
        .then((response) => {
          this.imgsrc = response.data.url.large
          this.srcset = `${response.data.url.large} 1600w,
          ${response.data.url.medium} 1200w,
          ${response.data.url.small} 800w`
          this.nameAlt = response.data.nameAlt
          this.title = response.data.title
          this.id = response.data.id
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  computed: {
  },
  watch: {
    'swipe.flag': {
      handler (newValue) {
        const body = document.getElementsByTagName('body')
        if (newValue) {
          body[0].classList.add('locked')
        } else {
          body[0].classList.remove('locked')
        }
      }
    }
  }
}
</script>
