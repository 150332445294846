export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    showArtist (name) {
      this.$router.push({ name: 'ArtistDetail', params: { name: name } })
    },
    showVenue (name) {
      this.$router.push({ name: 'VenueDetail', params: { title: name } })
    },
    showEvent (eventId) {
      this.$router.push({ name: 'EventDetail', params: { id: eventId } })
    }
  },
  computed: {
    getDow () {
      return (dowNo) => {
        let dow = ''
        switch (dowNo) {
          case 0:
            dow = 'SUN'
            break
          case 1:
            dow = 'MON'
            break
          case 2:
            dow = 'TUE'
            break
          case 3:
            dow = 'WED'
            break
          case 4:
            dow = 'THU'
            break
          case 5:
            dow = 'FRI'
            break
          case 6:
            dow = 'SAT'
            break
        }
        return dow
      }
    },
    // yy-mm-ddから年月日表示
    dateToStr () {
      return (ymd) => {
        const date = new Date(ymd)
        return date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate() + ' ' + this.getDow(date.getUTCDay())
      }
    }
  },
  watch: {
  }
}
