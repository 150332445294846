import axios from 'axios'
import { createStore } from 'vuex'

export default createStore({
  state: {
    events: {},
    artists: '',
    venues: '',
    eventDetail: '',
    artistDetail: '',
    venueDetail: ''
  },
  getters: {
    events: (state) => state.events,
    artists: (state) => state.artists,
    venues: (state) => state.venues,
    eventDetail: (state) => state.eventDetail,
    artistDetail: (state) => state.artistDetail,
    venueDetail: (state) => state.venueDetail
  },
  mutations: {
    events (state, events) {
      state.events = events
    },
    artists (state, artists) {
      state.artists = artists
    },
    venues (state, venues) {
      state.venues = venues
    },
    eventDetail (state, eventDetail) {
      state.eventDetail = eventDetail
    },
    artistDetail (state, artistDetail) {
      state.artistDetail = artistDetail
    },
    venueDetail (state, venueDetail) {
      state.venueDetail = venueDetail
    }
  },
  actions: {
    //
    // 【get】特定日付のイベント取得
    //
    async loadDailyEvents ({ commit }, ymd) {
      commit('events', {})
      return new Promise((resolve) => {
        axios
          .get('/wp-json/mmt/v1/events/' + ymd)
          .then((response) => {
            console.log(response.data)
            commit('events', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    //
    // 【get】アーティスト一覧
    //
    async getArtists ({ commit }) {
      return new Promise((resolve) => {
        axios
          .get('/wp-json/mmt/v1/artists')
          .then((response) => {
            console.log(response.data)
            commit('artists', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    //
    // 【get】アーティスト詳細
    //
    loadArtistDetail ({ commit }, name) {
      commit('artistDetail', '')
      return new Promise((resolve) => {
        axios
          .get('/wp-json/mmt/v1/artist/' + name)
          .then((response) => {
            console.log(response.data)
            commit('artistDetail', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    //
    // 【get】ライブハウス一覧
    //
    async getVenues ({ commit }) {
      return new Promise((resolve) => {
        axios
          .get('/wp-json/mmt/v1/venues')
          .then((response) => {
            console.log(response.data)
            commit('venues', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    //
    // 【get】ライブハウス詳細
    //
    async loadVenueDetail ({ commit }, title) {
      commit('venueDetail', '')
      return new Promise((resolve) => {
        axios
          .get('/wp-json/mmt/v1/venue/' + title)
          .then((response) => {
            console.log(response.data)
            commit('venueDetail', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    //
    // 【get】イベント詳細
    //
    async loadEventDetail ({ commit }, id) {
      commit('eventDetail', '')
      return new Promise((resolve) => {
        axios
          .get('/wp-json/mmt/v1/eventid/' + id)
          .then((response) => {
            console.log(response.data)
            commit('eventDetail', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    //
    // set body class inv
    //
    setBodyClassInv () {
      const body = document.querySelector('body')
      body.classList.add('inv')
    },
    //
    // remove body class inv
    //
    removeBodyClassInv () {
      const body = document.querySelector('body')
      body.classList.remove('inv')
    }
  },
  modules: {
  }
})
