<template>
  <header id="siteheader">
    <h1><router-link :to="{ path: '/' }">Mootoo</router-link></h1>
    <nav class="circlemenu" :class="{ on: menu }">
      <button @click="toggleMenu"></button>
      <ul>
        <li class="calendar"><router-link :to="{ name: 'Calendar' }"></router-link></li>
        <li class="venue"><router-link :to="{ name: 'VenueList' }"></router-link></li>
        <li class="artist"><router-link :to="{ name: 'ArtistList' }"></router-link></li>
        <li class="mootoo"><a href="https://www.instagram.com/mootoo_media" target="_blank"></a></li>
      </ul>
    </nav>
  </header>
  <main>
    <div class="inner">
      <router-view/>
    </div>
  </main>
  <footer id="sitefooter">
    <div class="bottomnavi">
      <div class="inner">
        <div class="left">
        </div>
        <div class="right">
          <ul>
            <li class="calendar"><router-link :to="{ name: 'Calendar' }">Calendar</router-link></li>
            <li class="venue"><router-link :to="{ name: 'VenueList' }">Venue</router-link></li>
            <li class="artist"><router-link :to="{ name: 'ArtistList' }">Pickup Artist / Musician / People</router-link></li>
          </ul>
          <a href="https://www.instagram.com/mootoo_media" target="_blank" class="mootoo">共感型アカウント<em>mootoo</em></a>
        </div>
      </div>
    </div>
    <div class="signature"></div>
    <div class="curl" @click.stop="mootoo" :class="{ on: curl }">
      <div @click.stop="toggleCurl"></div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios'
// axios.defaults.baseURL = 'https://boiler.xsrv.jp/DEV/mootoo'
axios.defaults.baseURL = 'https://splice-design.jp/WP'
export default {
  data () {
    return {
      curl: false,
      menu: false
    }
  },
  mounted () {
    // this.$store.dispatch('getArtists')
    // this.$store.dispatch('getVenues')
  },
  methods: {
    toggleMenu () {
      this.menu = !this.menu
      if (this.$route.name === 'Calendar') {
        this.$router.push({ name: 'Mootoo' })
      }
    },
    toggleCurl (e) {
      e.preventDefault()
      this.curl = !this.curl
    },
    mootoo (e) {
      e.preventDefault()
      const url = 'https://marsmoo.net/'
      window.open(url, '_blank')
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
