import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'

createApp(App)
  .use(store)
  .use(router)
  .use(VueGtag, {
    id: 'G-7NTG8Q0EE8'
  })
  .mount('#app')
