import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Mootoo',
    component: HomeView
  },
  {
    // イベント詳細
    path: '/event/:id',
    name: 'EventDetail',
    component: () => import(/* webpackChunkName: "EventDetail" */ '../views/EventDetail.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('loadEventDetail', to.params.id)
      next()
    }
  },
  {
    // イベントカレンダー
    path: '/calendar',
    children: [
      {
        // カレンダー
        path: '',
        name: 'Calendar',
        component: () => import(/* webpackChunkName: "Calendar" */ '../views/EventCalendar.vue')
      },
      {
        // 日付個別
        path: ':ymd',
        name: 'SpecificDate',
        component: () => import(/* webpackChunkName: "SpecificDate" */ '../views/SpecificDate.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('loadDailyEvents', to.params.ymd)
          next()
        }
      }
    ]
  },
  {
    // ライブハウス
    path: '/venue',
    children: [
      {
        // 一覧
        path: '',
        name: 'VenueList',
        component: () => import(/* webpackChunkName: "VenueList" */ '../views/VenueList.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('getVenues')
          next()
        }
      },
      {
        // 詳細
        path: ':title',
        name: 'VenueDetail',
        component: () => import(/* webpackChunkName: "VenueDetail" */ '../views/VenueDetail.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('loadVenueDetail', to.params.title)
          next()
        }
      }
    ]
  },
  {
    // アーティスト
    path: '/artist',
    children: [
      {
        // 一覧
        path: '',
        name: 'ArtistList',
        component: () => import(/* webpackChunkName: "ArtistList" */ '../views/ArtistList.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('getArtists')
          next()
        }
      },
      {
        // 詳細
        path: ':name',
        name: 'ArtistDetail',
        component: () => import(/* webpackChunkName: "ArtistDetail" */ '../views/ArtistDetail.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('loadArtistDetail', to.params.name)
          next()
        }
      }
    ]
  }
]

const router = createRouter({
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
