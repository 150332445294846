<template>
  <div class="home">
    <keep-alive>
      <HeroSection></HeroSection>
    </keep-alive>
    <keep-alive>
      <UpcommingEvents></UpcommingEvents>
    </keep-alive>
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue'
import UpcommingEvents from '@/components/UpcommingEvents.vue'

export default {
  components: {
    HeroSection,
    UpcommingEvents
  },
  mounted () {
    const body = document.querySelector('body')
    body.classList.remove('inv')
    document.title = 'Mootoo 松本市夜の社交場情報 Matsumoto City Night out Guide'
    document.querySelector('meta[name="description"]').setAttribute('content', 'Mootoo 松本夜の社交場情報♪ 松本市内のライブハウスやイベントスペースで開催される最新イベント情報を掲載中')
  }
}
</script>
