<template>
  <section id="upcomming-events">
    <div class="events">
      <ul v-for="(date, index) in events" :key="index">
        <h1 class="datelabel" :class="{ today: isToday(index) }">
          <div v-html="dateStr(index)"></div>
        </h1>
        <li v-for="(event, index2) in date" :key="index2" :class="{ noimg: !event.img.large }">
          <template v-if="event.img.large">
            <figure>
              <img
                :src="event.img.large"
                sizes="(max-width: 599px) 100vw, 60vw"
                :srcset="srcset(event.img)"
                :alt="event.title"
              >
              <div class="sleeve sp">
                <div @click="showEvent(event.ID)">more</div>
              </div>
            </figure>
            <div>
              <div class="venue">
                <span
                  v-for="venue in event.venue"
                  :key="venue.ID"
                  @click="showVenue(venue.name_alt)"
                >{{ venue.name }}</span>
              </div>
              <h2>
                <em>{{ event.title }}</em>
                <div class="subtitle" v-if="event.subtitle">{{ event.subtitle }}</div>
                <div class="genre" v-if="event.genre">{{ dispGenre(event.genre) }}</div>
              </h2>
              <dl class="meta">
                <dt><span>DATE</span></dt>
                <dd>{{ dateToStr(event.date) }}</dd>
                <dt><span>START</span></dt>
                <dd>{{ event.start }}</dd>
                <dt><span>ENTRANCE</span></dt>
                <dd>{{ event.entrance }}</dd>
              </dl>
              <dl class="meta ticket" v-if="event.ticketUrl">
                <dt><span>TICKET</span></dt>
                <dd><a :href="event.ticketUrl" target="_blank">{{ event.ticketUrl }}</a></dd>
              </dl>
              <div class="content">
                <div class="artists" v-html="event.artists"></div>
                <div class="desc" v-if="event.excerpt">
                  <div v-html="eventDesc(event)"></div>
                  <i
                    v-if="event.desc.length > 200"
                    @click="toggleExcerpt(event)"
                  >...</i>
                </div>
              </div>
              <div class="sleeve pc">
                <div @click="showEvent(event.ID)">more</div>
              </div>
            </div>
          </template>
          <template v-else>
            <div>
              <div class="venue">
                <span
                  v-for="venue in event.venue"
                  :key="venue.ID"
                  @click="showVenue(venue.name_alt)"
                >{{ venue.name }}</span>
              </div>
              <h2>
                <em>{{ event.title }}</em>
                <div class="subtitle" v-if="event.subtitle">{{ event.subtitle }}</div>
                <div class="genre" v-if="event.genre">{{ dispGenre(event.genre) }}</div>
              </h2>
              <dl class="meta">
                <dt><span>DATE</span></dt>
                <dd>{{ dateToStr(event.date) }}</dd>
                <dt><span>START</span></dt>
                <dd>{{ event.start }}</dd>
                <dt><span>ENTRANCE</span></dt>
                <dd>{{ event.entrance }}</dd>
              </dl>
            </div>
            <div class="content">
              <div class="artists" v-html="event.artists"></div>
              <div class="desc" v-if="event.excerpt">
                <div v-html="eventDesc(event)"></div>
                <i
                  v-if="event.desc.length > 200"
                  @click="toggleExcerpt(event)"
                >...</i>
              </div>
            </div>
            <div class="sleeve">
              <div @click="showEvent(event.ID)">more</div>
            </div>
          </template>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import utility from '@/mixins/utility.js'
export default {
  mixins: [utility],
  data () {
    return {
      events: {}
    }
  },
  mounted () {
    if (this.$route.params.title) {
      // venue
      axios
        .get('/wp-json/mmt/v1/event/venue/' + this.$route.params.title)
        .then((response) => {
          console.log(response.data)
          this.events = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    } else if (this.$route.params.name) {
      // artist
      axios
        .get('/wp-json/mmt/v1/event/artist/' + this.$route.params.name)
        .then((response) => {
          console.log(response.data)
          this.events = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      axios
        .get('/wp-json/mmt/v1/event')
        .then((response) => {
          console.log(response.data)
          this.events = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  methods: {
    toggleExcerpt (event) {
      if (event.omit === undefined) {
        event.omit = false
      } else {
        event.omit = !event.omit
      }
    }
  },
  computed: {
    //
    // イベントを日付順に整理
    eventListEachDate () {
      return '@@@'
    },
    dateStr () {
      return (str) => {
        const date = new Date(str)
        return `<div class="month">${date.getMonth() + 1}</div><div class="date">${date.getDate()}<span class="dow">${this.getDow(date.getDay())}</span></div>`
      }
    },
    isToday () {
      return (ymd) => {
        const theday = new Date(ymd)
        const today = new Date()
        return theday.getFullYear() === today.getFullYear() && theday.getMonth() === today.getMonth() && theday.getDate() === today.getDate()
      }
    },
    srcset () {
      return (img) => {
        return img.large + ' 1600w,' + img.medium + ' 1200w,' + img.small + ' 800w'
      }
    },
    dispGenre () {
      return (str) => {
        return str.replace(/,/g, '／')
      }
    },
    eventDesc () {
      return (event) => {
        return event.omit === undefined || event.omit ? event.excerpt : event.desc
      }
    }
  },
  watch: {
  }
}
</script>
